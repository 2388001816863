import "../../node_modules/@babel/polyfill";
import 'select2/dist/css/select2.css';
import '../sass/app.css'; // the order in this file determines where things are loaded by MiniCSSExtractLoader
import 'zurb-foundation-5/scss/normalize.scss';
import 'zurb-foundation-5/scss/foundation.scss';
import './seatpicker/seatpicker.scss';
import '../sass/backend.scss';
import '../sass/animate.css';
import $ from 'jquery';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/spinner.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'jquery-ui/ui/widgets/spinner.js';
import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/ui/widgets/sortable.js';
import 'select2';
import 'bootstrap-daterangepicker/daterangepicker.js';
import Modernizr from 'modernizr';
window.Modernizr = Modernizr;
import 'zurb-foundation-5/js/foundation/foundation.js';
import 'zurb-foundation-5/js/foundation/foundation.reveal.js';
import 'zurb-foundation-5/js/foundation/foundation.alert.js';
import 'zurb-foundation-5/js/foundation/foundation.topbar.js';
import React from 'react';
import ReactDOM from 'react-dom';
import SeatPickerModalContent from './seatpicker/seatpickerModal.jsx';


var el = document.getElementById('seat-picker-modal-container');
if (el !== null) {
    ReactDOM.render(<SeatPickerModalContent url="/api/seatmap" />, el);
}

$(document).foundation();

$(document).ready(function(){
    $('.date-picker-field').each(function() {
        const val = $(this).val();
        $(this).datepicker({
            dateFormat: 'yy-mm-dd',
            defaultDate: $.datepicker.parseDate('yy-mm-dd', val)
        });
    });

    $('input:enabled.spinner-field').spinner();

    $("#clearTrain1Radio").click(function() {
        $('input:radio[name=tTrain_id_1]:checked').prop('checked', false);
    });
    $("#clearTrain2Radio").click(function() {
        $('input:radio[name=tTrain_id_2]:checked').prop('checked', false);
    });
});
